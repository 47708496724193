<template>
  <div class="reply-editor" :class="paddingClass">
    <div class="reply-editor__content">
      <message-preview
        :message="message"
        :show-message-type="true"
        :default-empty-message="$t('CONVERSATION.REPLY_MESSAGE_NOT_FOUND')"
        class="reply-editor__message-preview"
      />
      <!-- Vista previa de la imagen o video a la derecha -->
      <div v-if="hasPreview" class="preview-container">
        <img
          v-if="isImage"
          :src="
            message.attachments[0].thumb_url || message.attachments[0].data_url
          "
          alt="Preview"
          class="preview-img"
        />
        <video
          v-else-if="isVideo"
          :src="message.attachments[0].data_url"
          playsinline
          class="preview-video"
        />
      </div>
    </div>

    <!-- Botón de dismiss a la derecha -->
    <woot-button
      color-scheme="secondary"
      icon="dismiss"
      variant="clear"
      size="medium"
      icon-size="16"
      class="reply-editor__dismiss-button"
      @click.stop="$emit('dismiss')"
    />
  </div>
</template>

<script>
import MessagePreview from 'dashboard/components/widgets/conversation/MessagePreviewReplyBox.vue';

export default {
  components: { MessagePreview },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  computed: {
    // Verifica si el mensaje tiene una vista previa de imagen o video
    hasPreview() {
      const attachments = this.message.attachments || [];
      return attachments.length > 0 && (this.isImage || this.isVideo);
    },

    // Verifica si el archivo adjunto es una imagen
    isImage() {
      return this.lastMessageFileType === 'image';
    },

    // Verifica si el archivo adjunto es un video
    isVideo() {
      return this.lastMessageFileType === 'video';
    },

    // Obtiene el tipo de archivo del último mensaje en el chat
    lastMessageFileType() {
      const [{ file_type: fileType } = {}] = this.message.attachments || [];
      return fileType;
    },

    // Determina la clase para el padding
    paddingClass() {
      return this.hasPreview ? 'has-preview' : 'no-preview';
    },
  },
};
</script>

<style lang="scss" scoped>
.reply-editor {
  height: 40px;
  gap: 10px;
  border-radius: 4px;
  border-top: 1px solid var(--conversa2-blue-400-color);
  border-right: 1px solid var(--conversa2-blue-400-color);
  border-bottom: 1px solid var(--conversa2-blue-400-color);
  border-left: 4px solid var(--conversa2-blue-400-color);
  background: var(--conversa2-blue-50-color);
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &.no-preview {
    padding: 8px 12px;
  }

  &.has-preview {
    padding: 12px 12px;
  }

  &_icon {
    flex-shrink: 0;
    margin-right: 0px !important;
  }

  &__content {
    font-family: Source Sans Pro;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__message-preview {
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 100%;
    white-space: normal;
    word-break: break-word;
    flex: 1;
  }

  &__dismiss-button {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  .preview-container {
    margin-left: 10px; /* Espacio entre la vista previa y el contenido del mensaje */
    width: 44px;
    height: 32px;
    overflow: hidden;
    border-radius: 2px;
    background-color: #f0f0f0;
  }

  .preview-img,
  .preview-video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Para que la imagen/video cubra el contenedor */
  }
}
</style>
