<template>
  <div class="context-menu">
    <woot-modal
      v-if="isCannedResponseModalOpen && showCannedResponseOption"
      :show.sync="isCannedResponseModalOpen"
      :on-close="hideCannedResponseModal"
    >
      <add-canned-modal
        :response-content="plainTextContent"
        :on-close="hideCannedResponseModal"
      />
    </woot-modal>
    <woot-button
      icon="chevron-down"
      color-scheme="white"
      variant="smooth"
      size="tiny-plus"
      @click="handleContextMenuClick"
    />
    <div
      v-if="isOpen && !isCannedResponseModalOpen"
      v-on-clickaway="handleContextMenuClick"
      class="dropdown-pane dropdown-pane--open"
      :class="`dropdown-pane--${menuPosition}`"
      :style="dropdownPosition"
    >
      <woot-dropdown-menu>
        <woot-dropdown-item v-if="showDelete" style="display: none;">
          <woot-button
            class="menu-item"
            variant="clear"
            color-scheme="alert"
            width="16"
            height="16"
            icon="delete"
            @click="handleDelete"
          >
            {{ $t('CONVERSATION.CONTEXT_MENU.DELETE') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="showReplyTo">
          <woot-button
            class="menu-item"
            variant="clear"
            icon="arrow-reply-menu"
            width="16"
            height="16"
            view-box="0 0 16 16"
            color-scheme="secondary"
            @click="handleReplyTo"
          >
            {{ $t('CONVERSATION.CONTEXT_MENU.REPLY_TO') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="showCopy">
          <woot-button
            class="menu-item"
            width="16"
            height="16"
            variant="clear"
            icon="copy-menu"
            color-scheme="secondary"
            @click="handleCopy"
          >
            {{ $t('CONVERSATION.CONTEXT_MENU.COPY') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="showCannedResponseOption">
          <woot-button
            class="menu-item"
            variant="clear"
            width="16"
            height="16"
            icon="comment-add"
            color-scheme="secondary"
            @click="showCannedResponseModal"
          >
            {{ $t('CONVERSATION.CONTEXT_MENU.CREATE_A_CANNED_RESPONSE') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="visibleTranslate">
          <woot-button
            class="menu-item"
            variant="clear"
            width="16"
            height="16"
            icon="translate"
            color-scheme="secondary"
            @click="handleTranslate"
          >
            {{ $t('CONVERSATION.CONTEXT_MENU.TRANSLATE') }}
          </woot-button>
        </woot-dropdown-item>
      </woot-dropdown-menu>
    </div>
  </div>
</template>
<script>
import alertMixin from 'shared/mixins/alertMixin';
import { mixin as clickaway } from 'vue-clickaway';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';

import AddCannedModal from 'dashboard/routes/dashboard/settings/canned/AddCanned';
import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu';
import { copyTextToClipboard } from 'shared/helpers/clipboard';
import { ACCOUNT_EVENTS } from '../../../helper/AnalyticsHelper/events';

export default {
  components: {
    AddCannedModal,
    WootDropdownMenu,
    WootDropdownItem,
  },
  mixins: [alertMixin, clickaway, messageFormatterMixin],
  props: {
    messageContent: {
      type: String,
      default: '',
    },
    messageSenderName: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    showCopy: {
      type: Boolean,
      default: false,
    },
    showReplyTo: {
      type: Boolean,
      default: false,
    },
    showDelete: {
      type: Boolean,
      default: false,
    },
    menuPosition: {
      type: String,
      default: 'left',
    },
    showCannedResponseOption: {
      type: Boolean,
      default: true,
    },
    isOnlyTextMessage: {
      type: Boolean,
      default: false,
    },
    hasReplyToMessage: {
      type: Boolean,
      default: false,
    },
    hasPreviewOfReplyMessage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCannedResponseModalOpen: false,
      visibleTranslate: false,
    };
  },
  computed: {
    plainTextContent() {
      return this.getPlainText(this.messageContent);
    },
    /**
     * Devuelve un string con la línea mas larga del mensaje, esto es necesario para calcular correctamente la posición del dopdown-pane cuando el mensaje tiene saltos de línea.
     * Si el mensaje no tiene saltos de línea, devuelve el mensaje tal cual.
     * @returns {String} Se obtiene la linea mas larga del mensaje.
     */
    getLongerLineMessage() {
      return this.messageContent.split('\n').reduce((longestLine, line) => {
        return line.length > longestLine.length ? line : longestLine;
      }, '');
    },
    /**
     * Devuelve un objeto con los datos de posición del menu de opciones.
     * @returns {Object} Objeto con los estilos CSS a aplicar.
     */
    dropdownPosition() {
      // --- Para las burbujas del contacto ---
      if (this.menuPosition === 'left') {
        // si tiene la opción "Responder" y es un mensaje de un canal de whatsapp
        return this.showReplyTo ? { left: '-108px' } : { left: '-80px' };
      }
      // --- Para las burbujas del agente ---
      // Si tiene la opción "Añadir a respuestas predefinidas" y es mensaje corto
      if (this.showCannedResponseOption && this.isOnlyTextMessage) {
        // Si contiene una referencia a otro mensaje
        if (this.hasReplyToMessage) {
          // se determina el tamaño del nombre del remitente
          let senderNameSize = '';
          const senderNameLength = this.messageSenderName.length;
          if (this.hasPreviewOfReplyMessage) {
            senderNameSize =
              senderNameLength < 12
                ? 'size1' // Si contentTextLength es menor a 12, devuelve 'size1'.
                : senderNameLength < 15
                ? 'size2' // Si contentTextLength es menor a 15, devuelve 'size2'.
                : 'default'; // valor por defecto
          } else {
            senderNameSize = senderNameLength < 10
                ? 'size1' // Si contentTextLength es menor a 10, devuelve 'size1'.
                : senderNameLength < 17
                ? 'size2' // Si contentTextLength es menor a 17, devuelve 'size2'.
                :  senderNameLength < 20
                ? 'size3' // Si contentTextLength es menor a 20, devuelve 'size3'.
                : senderNameLength < 23
                ? 'size4' // Si contentTextLength es menor a 23, devuelve 'size4'.
                : senderNameLength < 25
                ? 'size5' // Si contentTextLength es menor a 25, devuelve 'size5'.
                : 'default'; // valor por defecto
          }

          // asignación de la posición según el tamaño del nombre del remitente
          switch (senderNameSize) {
            case 'size1':
              return this.hasPreviewOfReplyMessage
                ? { left: '-40px' } // video o imagen
                : { left: '-62px' }; // texto o audio
            case 'size2':
              return this.hasPreviewOfReplyMessage
                ? { left: '-20px' } // video o imagen
                : { left: '-52px' }; // texto o audio
            case 'size3':
              return { left: '-40px' }; // texto o audio
            case 'size4':
              return { left: '-20px' }; // texto o audio
            case 'size5':
              return { left: '-10px' }; // texto o audio
            default:
              return { left: '0px' }; // valor por defecto
          }
        }

        // Si no contiene una referencia a otro mensaje se calcula la posición según el tamaño del contenido del mensaje
        const contentTextLength = this.getLongerLineMessage.length;
        let contentTextSize =
          contentTextLength < 15
            ? 'size1' // Si contentTextLength es menor a 15, devuelve 'size1'.
            : contentTextLength < 18
            ? 'size2' // Si contentTextLength es menor a 18, devuelve 'size2'.
            : contentTextLength < 21
            ? 'size3' // Si contentTextLength es menor a 21, devuelve 'size3'.
            : contentTextLength < 24
            ? 'size4' // Si contentTextLength es menor a 24, devuelve 'size4'.
            : 'default'; // valor por defecto

        switch (contentTextSize) {
          case 'size1':
            return { left: '-70px' };
          case 'size2':
            return { left: '-60px' };
          case 'size3':
            return { left: '-50px' };
          case 'size4':
            return { left: '-30px' };
          default:
            return { left: '0px' };
        }
      }
      return { left: '0px' };
    },
  },
  methods: {
    handleContextMenuClick() {
      this.$emit('toggle', !this.isOpen);
    },
    /**
     * Emite eventos para manejar la respuesta a un mensaje y alternar el estado de visibilidad.
     *
     * @returns {void} No devuelve ningún valor.
     */
    handleReplyTo() {
      this.$emit('reply-to', this.message);
      this.$emit('toggle', false);
    },
    async handleCopy() {
      await copyTextToClipboard(this.plainTextContent);
      this.showAlert(this.$t('CONTACT_PANEL.COPY_SUCCESSFUL'));
      this.$emit('toggle', false);
    },
    handleDelete() {
      this.$emit('delete');
    },
    hideCannedResponseModal() {
      this.isCannedResponseModalOpen = false;
      this.$emit('toggle', false);
    },
    showCannedResponseModal() {
      this.$track(ACCOUNT_EVENTS.ADDED_TO_CANNED_RESPONSE);
      this.isCannedResponseModalOpen = true;
    },

    handleTranslate() {
      this.$emit('translate');
      this.handleContextMenuClick();
    },
  },
};
</script>
<style lang="scss" scoped>
.menu-item {
  font-size: var(--font-size-default);
  font-weight: var(--font-weight-normal);
  line-height: var(--space-medium);
  text-align: left;
}
.dropdown-pane {
  box-shadow: none;
  bottom: 2.7rem;
  border-radius: var(--space-smaller-plus);
  top: auto;
  left: 0;
  border: 1px solid var(--conversa2-neutral-100-color);
}
.dropdown-pane--left {
  display: inline-table;
}
.dropdown-pane--right {
  display: inline-table;
}
.button {
  &.clear {
    &.secondary {
      color: var(--conversa2-neutral-800-color);
      font-size: var(--font-size-default);
      line-height: var(--space-medium);

      &:hover {
        color: var(--conversa2-neutral-800-color);
        background-color: var(--conversa2-neutral-50-color);
      }
    }
  }
}
</style>
