<template>
  <div class="message-container">
    <span
      v-if="message.content && !message.attachments"
      class="message-text message-content"
    >
      {{ parsedLastMessage }}
    </span>
    <span
      v-else-if="message.content && message.attachments"
      class="message-text"
    >
      <fluent-icon
        v-if="attachmentIcon && showMessageType"
        size="16"
        class="icon-attachment"
        :icon="attachmentIcon"
      />
      <span class="message-content"> {{ parsedLastMessage }} </span>
    </span>
    <!-- Solo adjuntos -->
    <span v-else-if="message.attachments" class="message-text">
      <fluent-icon
        v-if="attachmentIcon && showMessageType"
        size="16"
        class="icon-attachment"
        :icon="attachmentIcon"
      />
      <!-- Si es un archivo, muestra el nombre del archivo -->
      <span v-if="isFile" class="message-content">
        {{ decodedFileName }}
      </span>

      <!-- Si es un audio, muestra la duración -->
      <span v-else-if="isAudio" class="message-content">
        {{ audioDuration }}
      </span>

      <!-- Si es otro tipo de archivo, muestra el contenido del mensaje -->
      <span v-else class="message-content">
        {{ $t(attachmentMessageContent) }}
      </span>
    </span>
    <!-- Mensaje vacío -->
    <span v-else class="message-text message-content">
      {{ defaultEmptyMessage || $t('CHAT_LIST.NO_CONTENT') }}
    </span>
  </div>
</template>

<script>
import { MESSAGE_TYPE } from 'widget/helpers/constants';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import { ATTACHMENT_ICONS } from 'shared/constants/messages';

export default {
  name: 'MessagePreview',
  mixins: [messageFormatterMixin],
  props: {
    message: {
      type: Object,
      required: true,
    },
    showMessageType: {
      type: Boolean,
      default: true,
    },
    defaultEmptyMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      audioDuration: null,
      audioObject: null,
    };
  },
  computed: {
    // Verifica si el archivo adjunto es un audio
    isAudio() {
      return this.lastMessageFileType === 'audio';
    },

    // Obtiene el tipo de archivo del último mensaje en el chat
    lastMessageFileType() {
      const [{ file_type: fileType } = {}] = this.message.attachments || [];
      return fileType;
    },

    /**
     * Obtiene el contenido parseado del último mensaje en el chat.
     * @returns {string} - El contenido parseado del último mensaje.
     */
    parsedLastMessage() {
      const { content_attributes: contentAttributes } = this.message;
      const { email: { subject } = {} } = contentAttributes || {};
      return this.getPlainText(subject || this.message.content);
    },

    /**
     * Obtiene el icono de adjunto para el último mensaje en el chat.
     * @returns {string} - El icono de adjunto correspondiente al tipo de archivo del último mensaje.
     */
    attachmentIcon() {
      return ATTACHMENT_ICONS[this.lastMessageFileType] || '';
    },

    /**
     * Obtiene el contenido del mensaje de adjunto.
     * @returns {string} - El contenido del mensaje de adjunto.
     */
    attachmentMessageContent() {
      return `CHAT_LIST.ATTACHMENTS.${this.lastMessageFileType}.CONTENT`;
    },
    // Verifica si el archivo adjunto es un archivo
    isFile() {
      return this.lastMessageFileType === 'file';
    },
    /**
     * Obtiene el nombre del archivo adjunto y lo decodifica.
     * @returns {string} - El nombre del archivo adjunto, decodificado.
     */
    decodedFileName() {
      const attachment = this.message.attachments[0];
      if (attachment && attachment.data_url) {
        const url = attachment.data_url;
        const fileNameWithExtension = url.substring(url.lastIndexOf('/') + 1);
        return decodeURIComponent(fileNameWithExtension);
      }
      return '';
    },
  },
  watch: {
    'message.attachments': 'loadAudioDuration',
  },
  mounted() {
    this.loadAudioDuration();
  },
  beforeDestroy() {
    if (this.audioObject) {
      this.audioObject.pause();
      this.audioObject = null;
    }
  },
  methods: {
    /**
     * Carga la duración del archivo de audio.
     */
    loadAudioDuration() {
      if (this.isAudio && this.message.attachments.length > 0) {
        const audioUrl = this.message.attachments[0].data_url;

        if (this.audioObject) {
          this.audioObject.pause();
        }

        this.audioObject = new Audio(audioUrl);

        this.audioObject.oncanplaythrough = () => {
          const duration = this.audioObject.duration;
          const minutes = Math.floor(duration / 60);
          const seconds = Math.floor(duration % 60);
          this.audioDuration = `${minutes}:${
            seconds < 10 ? '0' : ''
          }${seconds}`;
        };

        this.audioObject.onerror = () => {
          this.audioDuration = '00:00';
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.message-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
}

.message-text {
  display: inline-flex;
  align-items: flex-start;
  flex: 1;
  max-width: 100%;
  font-size: var(--font-size-mini);
  font-weight: var(--font-weight-normal);
  line-height: var(--space-normal);
}

.icon-attachment {
  margin-right: 0.4rem;
  flex-shrink: 0;
  align-self: flex-start;
}

.message-content {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Limitar a dos líneas */
  overflow: hidden; /* Ocultar el texto que se excede */
}
</style>
