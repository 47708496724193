<template>
  <div>
    <div
      class="reply-to-container"
      :class="{
        'incoming-message': messageType === MESSAGE_TYPE.INCOMING,
        'outgoing-message': messageType === MESSAGE_TYPE.OUTGOING,
        'no-attachments': !parentHasAttachments,
      }"
      @click="scrollToMessage"
    >
      <message-preview
        :message="message"
        :show-message-type="true"
        :default-empty-message="$t('CONVERSATION.REPLY_MESSAGE_NOT_FOUND')"
      />
    </div>
    <div class="separator" />
  </div>
</template>

<script>
import MessagePreview from 'dashboard/components/widgets/conversation/MessagePreview.vue';
import { MESSAGE_TYPE } from 'shared/constants/messages';
import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  name: 'ReplyTo',
  components: {
    MessagePreview,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    messageType: {
      type: Number,
      required: true,
    },
    parentHasAttachments: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return { MESSAGE_TYPE };
  },
  methods: {
    // Emite un evento para desplazar la vista hasta el mensaje especificado por `message.id`.
    scrollToMessage() {
      bus.$emit(BUS_EVENTS.SCROLL_TO_MESSAGE, {
        messageId: this.message.id,
      });
    },
  },
};
</script>

<style scoped>
.reply-to-container {
  border-radius: 0.25rem;
  min-width: 10rem;
  cursor: pointer;
}

.separator {
}

.incoming-message {
  padding: 4px 4px 4px 12px;
  border-radius: 4px;
  border-top: 1px solid var(--conversa2-blue-400-color);
  border-right: 1px solid var(--conversa2-blue-400-color);
  border-bottom: 1px solid var(--conversa2-blue-400-color);
  border-left: 4px solid var(--conversa2-blue-400-color);
  background: var(--conversa2-blue-50-color);

  font-size: var(--font-size-mini);
  font-weight: var(--font-weight-normal);
  line-height: var(--space-normal);
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.outgoing-message {
  padding: 4px 4px 4px 12px;
  border-radius: 4px;
  border-top: 1px solid var(--conversa2-blue-400-color);
  border-right: 1px solid var(--conversa2-blue-400-color);
  border-bottom: 1px solid var(--conversa2-blue-400-color);
  border-left: 4px solid var(--conversa2-blue-400-color);
  background: var(--conversa2-blue-50-color);
  font-size: var(--font-size-mini);
  font-weight: var(--font-weight-normal);
  line-height: var(--space-normal);
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}

.no-attachments {
  margin: 0;
}
</style>
