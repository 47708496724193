<template>
  <div class="view-box fill-height">
    <banner
      v-if="!currentChat.can_reply && !isIntertelUCInbox"
      color-scheme="alert"
      :banner-message="replyWindowBannerMessage"
      :href-link="replyWindowLink"
      :href-link-text="replyWindowLinkText"
    />

    <banner
      v-if="isATweet"
      color-scheme="gray"
      :banner-message="tweetBannerText"
      :has-close-button="hasSelectedTweetId"
      @close="removeTweetSelection"
    />

    <div class="sidebar-toggle__wrap">
      <woot-button
        variant="smooth"
        size="tiny"
        color-scheme="secondary"
        class="sidebar-toggle--button"
        :icon="isRightOrLeftIcon"
        @click="onToggleContactPanel"
      />
    </div>
    <ul class="conversation-panel">
      <div
        class="bubble-container"
        :style="{ visibility: showBubbleDate ? 'visible' : 'hidden' }"
      >
        <div class="bubble-date">
          {{ formattedDate }}
        </div>
      </div>
      <transition name="slide-up">
        <li class="spinner--container">
          <span v-if="shouldShowSpinner" class="spinner message" />
        </li>
      </transition>
      <message
        v-for="message in getReadMessages"
        :key="message.id"
        class="message--read ph-no-capture"
        :data="message"
        :created_at_bubble="message.created_at"
        :is-a-tweet="isATweet"
        :is-a-whatsapp-channel="isAWhatsAppChannel"
        :has-instagram-story="hasInstagramStory"
        :is-web-widget-inbox="isAWebWidgetInbox"
        :channel-type="currentChat.meta.channel"
        :inbox-supports-reply-to="inboxSupportsReplyTo"
        :in-reply-to="getInReplyToMessage(message)"
        @load="onLoaded"
      />
      <li v-show="unreadMessageCount != 0" class="unread--toast">
        <span class="">
          {{ unreadMessageCount }}
          {{
            unreadMessageCount > 1
              ? $t('CONVERSATION.UNREAD_MESSAGES')
              : $t('CONVERSATION.UNREAD_MESSAGE')
          }}
        </span>
      </li>
      <message
        v-for="message in getUnReadMessages"
        :key="message.id"
        class="message--unread ph-no-capture"
        :data="message"
        :is-a-tweet="isATweet"
        :is-a-whatsapp-channel="isAWhatsAppChannel"
        :has-instagram-story="hasInstagramStory"
        :is-web-widget-inbox="isAWebWidgetInbox"
        :inbox-supports-reply-to="inboxSupportsReplyTo"
        :in-reply-to="getInReplyToMessage(message)"
        :channel-type="currentChat.meta.channel"
        @load="onLoaded"
      />
    </ul>
    <div
      class="conversation-footer"
      :class="{ 'modal-mask': isPopoutReplyBox }"
    >
      <div v-if="isAnyoneTyping" class="typing-indicator-wrap">
        <div class="typing-indicator">
          {{ typingUserNames }}
          <img
            class="gif"
            src="~dashboard/assets/images/typing.gif"
            alt="Someone is typing"
          />
        </div>
      </div>
      <reply-box
        :conversation-id="currentChat.id"
        :is-a-tweet="isATweet"
        :selected-tweet="selectedTweet"
        :popout-reply-box.sync="isPopoutReplyBox"
        @click="showPopoutReplyBox"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import ReplyBox from './ReplyBox';
import Message from './Message';
import conversationMixin from '../../../mixins/conversations';
import Banner from 'dashboard/components/ui/Banner.vue';
import { getTypingUsersText } from '../../../helper/commons';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import { REPLY_POLICY } from 'shared/constants/links';
import inboxMixin, { INBOX_FEATURES } from 'shared/mixins/inboxMixin';

import { isEscape } from 'shared/helpers/KeyboardHelpers';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';

export default {
  components: {
    Message,
    ReplyBox,
    Banner,
  },
  mixins: [conversationMixin, inboxMixin, eventListenerMixins],
  props: {
    isContactPanelOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isLoadingPrevious: true,
      heightBeforeLoad: null,
      conversationPanel: null,
      selectedTweetId: null,
      isPopoutReplyBox: false,
      shouldAutoScroll: true,
      showBubbleDate: false,
      formattedDate: '',
      scrollTimer: null,
    };
  },

  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      allConversations: 'getAllConversations',
      inboxesList: 'inboxes/getInboxes',
      listLoadingStatus: 'getAllMessagesLoaded',
      loadingChatList: 'getChatListLoadingStatus',
    }),
    inboxId() {
      return this.currentChat.inbox_id;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
    hasSelectedTweetId() {
      return !!this.selectedTweetId;
    },
    tweetBannerText() {
      return !this.selectedTweetId
        ? this.$t('CONVERSATION.SELECT_A_TWEET_TO_REPLY')
        : `
          ${this.$t('CONVERSATION.REPLYING_TO')}
          ${this.selectedTweet.content}` || '';
    },
    typingUsersList() {
      const userList = this.$store.getters[
        'conversationTypingStatus/getUserList'
      ](this.currentChat.id);
      return userList;
    },
    isAnyoneTyping() {
      const userList = this.typingUsersList;
      return userList.length !== 0;
    },
    typingUserNames() {
      const userList = this.typingUsersList;

      if (this.isAnyoneTyping) {
        const userListAsName = getTypingUsersText(userList, this.$root.$i18n);
        return userListAsName;
      }

      return '';
    },
    getMessages() {
      const [chat] = this.allConversations.filter(
        c => c.id === this.currentChat.id
      );
      return chat;
    },
    getReadMessages() {
      const chat = this.getMessages;
      return chat === undefined ? null : this.readMessages(chat);
    },
    getUnReadMessages() {
      const chat = this.getMessages;
      return chat === undefined ? null : this.unReadMessages(chat);
    },
    shouldShowSpinner() {
      return (
        (this.getMessages && this.getMessages.dataFetched === undefined) ||
        (!this.listLoadingStatus && this.isLoadingPrevious)
      );
    },

    shouldLoadMoreChats() {
      return !this.listLoadingStatus && !this.isLoadingPrevious;
    },

    conversationType() {
      const { additional_attributes: additionalAttributes } = this.currentChat;
      const type = additionalAttributes ? additionalAttributes.type : '';
      return type || '';
    },

    isATweet() {
      return this.conversationType === 'tweet';
    },

    hasInstagramStory() {
      return this.conversationType === 'instagram_direct_message';
    },

    selectedTweet() {
      if (this.selectedTweetId) {
        const { messages = [] } = this.getMessages;
        const [selectedMessage] = messages.filter(
          message => message.id === this.selectedTweetId
        );
        return selectedMessage || {};
      }
      return '';
    },
    isRightOrLeftIcon() {
      if (this.isContactPanelOpen) {
        return 'arrow-chevron-right';
      }
      return 'arrow-chevron-left';
    },
    getLastSeenAt() {
      const { contact_last_seen_at: contactLastSeenAt } = this.currentChat;
      return contactLastSeenAt;
    },

    replyWindowBannerMessage() {
      if (this.isAWhatsAppChannel) {
        return this.$t('CONVERSATION.TWILIO_WHATSAPP_CAN_REPLY');
      }
      if (this.isAPIInbox) {
        const { additional_attributes: additionalAttributes = {} } = this.inbox;
        if (additionalAttributes) {
          const {
            agent_reply_time_window_message: agentReplyTimeWindowMessage,
          } = additionalAttributes;
          return agentReplyTimeWindowMessage;
        }
        return '';
      }
      return this.$t('CONVERSATION.CANNOT_REPLY');
    },
    replyWindowLink() {
      if (this.isAWhatsAppCloudChannel) {
        return REPLY_POLICY.FACEBOOK;
      }
      if (this.isATwilioWhatsAppChannel) {
        return REPLY_POLICY.TWILIO_WHATSAPP;
      }
      if (this.isAFacebookInbox) {
        return REPLY_POLICY.MESSENGER;
      }
      return '';
    },
    replyWindowLinkText() {
      if (this.isAWhatsAppChannel) {
        return this.$t('CONVERSATION.24_HOURS_WINDOW');
      }
      if (!this.isAPIInbox) {
        return this.$t('CONVERSATION.TWILIO_WHATSAPP_24_HOURS_WINDOW');
      }
      return '';
    },
    unreadMessageCount() {
      return this.currentChat.unread_count;
    },
    /**
     * Comprueba si el buzón de entrada admite la función de respuesta.
     * @returns {Object} - Un objeto con dos propiedades: incoming y outgoing, que indican si el buzón admite la respuesta entrante y saliente, respectivamente.
     */
    inboxSupportsReplyTo() {
      const incoming = this.inboxHasFeature(INBOX_FEATURES.REPLY_TO);
      const outgoing =
        this.inboxHasFeature(INBOX_FEATURES.REPLY_TO_OUTGOING) &&
        !this.is360DialogWhatsAppChannel;

      return { incoming, outgoing };
    },
  },

  watch: {
    currentChat(newChat, oldChat) {
      if (newChat.id === oldChat.id) {
        return;
      }
      this.selectedTweetId = null;
      this.$nextTick(() => {
        this.$nextTick(() => {
          this.updateBubbleTimer();
        });
      });
    },
  },

  created() {
    bus.$on(BUS_EVENTS.SCROLL_TO_MESSAGE, this.onScrollToMessage);
    bus.$on(BUS_EVENTS.SET_TWEET_REPLY, this.setSelectedTweet);
  },

  mounted() {
    this.$nextTick(() => {
      this.addScrollListener();
    });
  },

  beforeDestroy() {
    this.removeBusListeners();
    this.removeScrollListener();
    this.removeMessageContainerObserver();
  },

  methods: {
    /**
     * Actualiza el temporizador de la burbuja de fecha.
     * Si no hay un temporizador activo o el scroll está en la parte superior,
     * oculta la burbuja de fecha; de lo contrario, la muestra y actualiza el temporizador.
     */
    updateBubbleTimer() {
      if (!this.scrollTimer || this.isScrollAtTop()) {
        this.showBubbleDate = false;
        return;
      }

      this.showBubbleDate = true;
      this.updateBubbleDate();
      this.startBubbleDateTimer();
    },
    /**
     * Verifica si el scroll está en la parte superior del panel de conversación.
     * @returns {boolean} Verdadero si el scroll está en la parte superior; de lo contrario, falso.
     */
    isScrollAtTop() {
      const panel = this.$el.querySelector('.conversation-panel');
      return panel.scrollTop === 0;
    },
    /**
     * Inicia el temporizador de la burbuja de fecha.
     * Si no hay un temporizador activo, establece uno nuevo para ocultar la burbuja después de 5000ms.
     */
    startBubbleDateTimer() {
      if (this.scrollTimer === null) {
        this.scrollTimer = setTimeout(() => {
          this.showBubbleDate = false;
          this.scrollTimer = null;
        }, 5000);
      } else {
        clearTimeout(this.scrollTimer); // Limpiar el temporizador existente
        this.scrollTimer = setTimeout(() => {
          this.showBubbleDate = false;
          this.scrollTimer = null;
        }, 5000);
      }
    },
    /**
     * Maneja el evento de desplazamiento del panel de conversación.
     * Actualiza la visualización de la burbuja de fecha según el scroll y la posición de los mensajes.
     * Oculta la burbuja si se ha alcanzado el final del scroll del panel.
     * @param {Event} e - Evento de desplazamiento.
     */
    handleScroll(e) {
      const margen = 100;
      this.shouldAutoScroll =
        e.target.scrollTop + e.target.clientHeight >=
        e.target.scrollHeight - margen;
      this.setScrollParams();

      const dataFetchCheck =
        this.getMessages.dataFetched === true && this.shouldLoadMoreChats;
      if (
        e.target.scrollTop < margen &&
        !this.isLoadingPrevious &&
        dataFetchCheck
      ) {
        this.isLoadingPrevious = true;
        this.$store
          .dispatch('fetchPreviousMessages', {
            conversationId: this.currentChat.id,
            before: this.getMessages.messages[0].id,
          })
          .then(() => {
            const heightDifference =
              this.conversationPanel.scrollHeight - this.heightBeforeLoad;
            this.conversationPanel.scrollTop =
              this.scrollTopBeforeLoad + heightDifference;
            this.isLoadingPrevious = false;
            this.setScrollParams();
          });
      }

      const isScrolledToBottom =
        e.target.scrollTop + e.target.clientHeight >=
        e.target.scrollHeight - margen + 50;

      if (isScrolledToBottom) {
        this.showBubbleDate = false;
      } else {
        this.updateBubbleDate();
        this.showBubbleDate = true;
        this.startBubbleDateTimer();
      }
    },
    /**
     * Actualiza la fecha en la burbuja de fecha basándose en el mensaje más antiguo visible en el panel de conversación.
     */
    updateBubbleDate() {
      const panel = this.$el.querySelector('.conversation-panel');
      const messages = panel.querySelectorAll('.message--read');
      const panelHeight = panel.clientHeight;
      const panelScrollTop = panel.scrollTop;

      let oldestVisibleMessage = null;
      let oldestMessageOffsetTop = Infinity; // Inicializar con un valor grande

      messages.forEach(message => {
        const messageOffsetTop = message.offsetTop;

        // Verificar si el mensaje está dentro del área visible del panel
        if (
          messageOffsetTop >= panelScrollTop &&
          messageOffsetTop <= panelScrollTop + panelHeight &&
          messageOffsetTop < oldestMessageOffsetTop // Comparar con el mensaje más alto hasta el momento
        ) {
          oldestMessageOffsetTop = messageOffsetTop;
          oldestVisibleMessage = message;
        }
      });

      if (oldestVisibleMessage) {
        const messageCreatedAt = oldestVisibleMessage.getAttribute(
          'created_at_bubble'
        );
        if (messageCreatedAt) {
          const milliseconds = parseInt(messageCreatedAt) * 1000;
          const date = new Date(milliseconds);
          const options = { day: 'numeric', month: 'long', year: 'numeric' };
          const formattedDate = date.toLocaleDateString('es-ES', options);
          this.formattedDate = formattedDate;
        }
      }
    },

    /**
     * Desplaza el panel de conversación hasta la parte inferior, mostrando los mensajes más recientes.
     *
     * Esta función establece la propiedad `scrollTop` del elemento del panel de conversación al valor de `scrollHeight`,
     * lo que hace que se desplace el contenido hasta la parte inferior.
     *
     * @function
     * @name scrollToBottom
     * @memberof NombreDeTuClaseAqui
     * @instance
     * @returns {void}
     */
    scrollToBottom() {
      this.conversationPanel.scrollTop = this.conversationPanel.scrollHeight;
    },

    /**
     * Observa el contenedor de mensajes para auto desplazamiento.
     *
     * Esta función crea un observador de mutaciones (MutationObserver) que supervisa el contenedor de mensajes (this.conversationPanel).
     * Cuando ocurren cambios en el contenedor (como la adición de nuevos mensajes), el observador verificará si el auto desplazamiento (shouldAutoScroll) está habilitado.
     * Si debe realizarse el auto desplazamiento, invoca el método scrollToBottom() para desplazar el panel hasta abajo y mostrar los mensajes más recientes.
     *
     * @function
     * @name observeMessagesContainer
     * @memberof NombreDeTuClaseAqui
     * @instance
     * @returns {void}
     */
    observeMessagesContainer() {
      const messagesContainer = this.conversationPanel;
      const observer = new MutationObserver(() => {
        // Verificar si el auto desplazamiento está habilitado (shouldAutoScroll)
        if (this.shouldAutoScroll) {
          this.scrollToBottom();
        }
      });
      const config = { childList: true, subtree: true };
      // Observar el contenedor de mensajes utilizando el observador y la configuración
      observer.observe(messagesContainer, config);
    },
    /**
     * Remueve el observador de mutaciones para evitar memory leaks.
     *
     * Esta función se encarga de desconectar el observador de mutaciones (MutationObserver) asociado al contenedor de mensajes.
     * Al desconectar el observador, se evita que siga observando el contenedor de mensajes una vez que el componente ha sido destruido,
     * lo que ayuda a prevenir memory leaks.
     *
     * @function
     * @name removeMessageContainerObserver
     * @memberof NombreDeTuClaseAqui
     * @instance
     * @returns {void}
     */
    removeMessageContainerObserver() {
      if (this.messageContainerObserver) {
        this.messageContainerObserver.disconnect();
      }
    },

    /**
     * Ejecutado cuando se ha cargado el componente.
     *
     * Esta función se activa después de que el componente se ha cargado completamente (onLoaded).
     * Si el auto desplazamiento (shouldAutoScroll) está habilitado, invoca el método scrollToBottom() utilizando $nextTick para asegurar que se realice después de que se hayan aplicado las actualizaciones en el DOM.
     *
     * @function
     * @name onLoaded
     * @memberof NombreDeTuClaseAqui
     * @instance
     * @returns {void}
     */
    onLoaded() {
      if (this.shouldAutoScroll) {
        this.$nextTick(() => this.scrollToBottom());
      }
    },
    removeBusListeners() {
      bus.$off(BUS_EVENTS.SCROLL_TO_MESSAGE, this.onScrollToMessage);
      bus.$off(BUS_EVENTS.SET_TWEET_REPLY, this.setSelectedTweet);
    },
    setSelectedTweet(tweetId) {
      this.selectedTweetId = tweetId;
    },
    // Desplaza la vista hasta un mensaje específico en la conversación, primero intentando buscarlo en el DOM.
    // Si el mensaje no está cargado, lo busca y luego realiza el desplazamiento.
    async onScrollToMessage({ messageId = null } = {}) {
      if (!messageId) {
        this.$nextTick(() => this.scrollToBottom());
        this.makeMessagesRead();
        return;
      }
      this.isProgrammaticScroll = true;

      let messageElement = document.getElementById('conversation-' + messageId);

      if (messageElement) {
        // Si el mensaje está en el DOM, intenta el scroll directamente
        messageElement.scrollIntoView({ behavior: 'smooth' });
      } else if (messageId) {
        await this.fetchMessagesUntilTarget(messageId);
        messageElement = document.getElementById('conversation-' + messageId);
        if (messageElement) {
          if (messageElement) {
            setTimeout(() => {
              messageElement.scrollIntoView({ behavior: 'smooth' });
            }, 100);
          }
        }
      }
      setTimeout(() => {
        if (messageElement) {
          // Establecer los estilos de borde y fondo en el .bubble
          if (messageElement) {
            messageElement.style.transition = 'all 1s'; // Animación de 1 segundo para los estilos
            messageElement.style.border = '1px solid #E0E0E1';
            messageElement.style.background = '#234E9C4C';
          }

          // Volver los estilos a su estado original después de 1 segundo
          setTimeout(() => {
            if (messageElement) {
              messageElement.style.border = ''; // Restablecer el borde
              messageElement.style.background = ''; // Restablecer el fondo
            }
          }, 1500);
        }
      }, 200);

      this.isProgrammaticScroll = false;
      this.makeMessagesRead();
    },
    // Carga los mensajes hasta el mensaje especificado por `messageId` si se cumplen las condiciones necesarias.
    // Después de la carga, ajusta la posición del scroll.
    async fetchMessagesUntilTarget(messageId) {
      const params = { target_message_id: messageId };

      const shouldLoadMoreMessages =
        this.currentChat.dataFetched === true &&
        !this.listLoadingStatus &&
        !this.isLoadingPrevious;

      if (shouldLoadMoreMessages) {
        this.isLoadingPrevious = true;

        try {
          await this.$store.dispatch('fetchMessagesUntilTarget', {
            conversationId: this.currentChat.id,
            params: params,
          });

          // Ajustar el scroll después de que la carga se complete
          this.$nextTick(() => {
            this.adjustScrollPosition();
          });
        } catch (error) {
          console.error('Error al cargar mensajes:', error);
        } finally {
          this.isLoadingPrevious = false;
        }
      }
    },
    // Ajusta la posición del scroll después de cargar nuevos mensajes, compensando el cambio en la altura.
    adjustScrollPosition() {
      const heightDifference =
        this.conversationPanel.scrollHeight - this.heightBeforeLoad;
      this.conversationPanel.scrollTop =
        this.scrollTopBeforeLoad + heightDifference;
      this.setScrollParams();
    },
    async fetchPreviousMessages() {
      this.setScrollParams();

      const shouldLoadMoreMessages = this.shouldLoadMoreMessages();

      if (shouldLoadMoreMessages) {
        this.isLoadingPrevious = true;

        try {
          await this.$store.dispatch('fetchPreviousMessages', {
            conversationId: this.currentChat.id,
            before: this.currentChat.messages[0].id,
          });
          this.adjustScrollPosition();
        } catch (error) {
          console.error('Error al cargar mensajes previos: ', error);
        } finally {
          this.isLoadingPrevious = false;
        }
      }
    },

    // Método auxiliar para comprobar si se deben cargar más mensajes
    shouldLoadMoreMessages() {
      return (
        this.currentChat.dataFetched === true &&
        !this.listLoadingStatus &&
        !this.isLoadingPrevious
      );
    },

    showPopoutReplyBox() {
      this.isPopoutReplyBox = !this.isPopoutReplyBox;
    },
    closePopoutReplyBox() {
      this.isPopoutReplyBox = false;
    },
    handleKeyEvents(e) {
      if (isEscape(e)) {
        this.closePopoutReplyBox();
      }
    },

    /**
     * Agrega un escuchador de eventos de desplazamiento al panel de conversación y realiza acciones iniciales.
     *
     * Esta función configura el panel de conversación (this.conversationPanel) al encontrar su elemento en el DOM utilizando el selector '.conversation-panel'.
     * A continuación, observa el contenedor de mensajes para habilitar el auto desplazamiento utilizando el método observeMessagesContainer().
     * Luego, agrega un evento 'scroll' al panel de conversación que invoca el método handleScroll cuando se produce un desplazamiento.
     * Además, invoca el método scrollToBottom() para desplazar el panel hasta abajo y mostrar los mensajes más recientes.
     * Finalmente, establece la variable isLoadingPrevious en falso, indicando que no se están cargando mensajes anteriores.
     *
     * @function
     * @name addScrollListener
     * @memberof NombreDeTuClaseAqui
     * @instance
     * @returns {void}
     */
    addScrollListener() {
      this.conversationPanel = this.$el.querySelector('.conversation-panel');
      this.observeMessagesContainer();
      this.conversationPanel.addEventListener('scroll', this.handleScroll);
      this.$nextTick(() => this.scrollToBottom());
      this.isLoadingPrevious = false;
    },
    removeScrollListener() {
      // eslint-disable-next-line no-unused-expressions
      this.conversationPanel?.removeEventListener('scroll', this.handleScroll);
    },
    onToggleContactPanel() {
      this.$emit('contact-panel-toggle');
    },
    setScrollParams() {
      this.heightBeforeLoad = this.conversationPanel.scrollHeight;
      this.scrollTopBeforeLoad = this.conversationPanel.scrollTop;
    },
    makeMessagesRead() {
      this.$store.dispatch('markMessagesRead', { id: this.currentChat.id });
    },
    removeTweetSelection() {
      this.selectedTweetId = null;
    },
    /**
     * Obtiene el mensaje al que se está respondiendo.
     * @param {Object} parentMessage - El mensaje del que se está respondiendo.
     * @returns {Object} - El mensaje al que se está respondiendo, si existe; de lo contrario, un objeto vacío.
     */
    getInReplyToMessage(parentMessage) {
      if (!parentMessage) return {};
      const inReplyToMessage = parentMessage.reply_to_message;
      if (!inReplyToMessage) return {};

      return inReplyToMessage;
    },
  },
};
</script>

<style scoped lang="scss">
.spinner--container {
  min-height: var(--space-jumbo);
}

.view-box.fill-height {
  height: auto;
  flex-grow: 1;
  min-width: 300px;
}

.modal-mask {
  &::v-deep {
    .ProseMirror-woot-style {
      max-height: 40rem;
    }

    .reply-box {
      border: 1px solid var(--color-border);
      max-width: 120rem;
      width: 70%;
    }

    .reply-box .reply-box__top {
      position: relative;
      min-height: 44rem;
    }

    .reply-box__top .input {
      min-height: 44rem;
    }

    .emoji-dialog {
      position: fixed;
      left: unset;
      position: absolute;
      bottom: var(--space-smaller);
    }
  }
}
.sidebar-toggle__wrap {
  display: flex;
  justify-content: flex-end;

  .sidebar-toggle--button {
    position: fixed;

    top: var(--space-mega);
    z-index: var(--z-index-very-high);

    background: var(--white);

    padding: inherit 0;
    border-top-left-radius: calc(
      var(--space-medium) + 1px
    ); /* 100px of height + 10px of border */
    border-bottom-left-radius: calc(
      var(--space-medium) + 1px
    ); /* 100px of height + 10px of border */
    border: 1px solid var(--color-border-light);
    border-right: 0;
    box-sizing: border-box;
  }
}

.bubble-container {
  display: inline-block;
  margin: 0 auto;
  position: sticky;
  top: 12px;
  z-index: 999;
}

.bubble-date {
  border-radius: 4px;
  background: var(--conversa2-blue-100-color);
  box-shadow: 0px 2px 12px 0px rgba(102, 117, 132, 0.6);
  padding: 8px 16px;
  color: var(--conversa2-neutral-800-color);
  font-size: 12px;
  font-style: normal;
  font-weight: var(--font-weight-normal);
  line-height: var(--space-normal);
}
</style>
