<template>
  <div :class="['message-wrapper', { 'has-preview': hasPreview }]">
    <div class="content-container">
      <label class="contact-name">
        {{ message.sender && message.sender.name ? message.sender.name : '' }}
      </label>

      <div class="message-container">
        <span
          v-if="message.content && !message.attachments"
          class="message-text message-content"
        >
          {{ parsedLastMessage }}
        </span>
        <span
          v-else-if="message.content && message.attachments"
          class="message-text"
        >
          <fluent-icon
            v-if="attachmentIcon && showMessageType"
            size="16"
            class="icon-attachment"
            :icon="attachmentIcon"
          />
          <span class="message-content">
            {{ parsedLastMessage }}
          </span>
        </span>
        <span v-else-if="message.attachments" class="message-text">
          <fluent-icon
            v-if="attachmentIcon && showMessageType"
            size="16"
            class="icon-attachment"
            :icon="attachmentIcon"
          />
          <!-- Si es audio, mostrar la duración -->
          <span v-if="isAudio" class="message-content">
            {{ audioDuration }}
          </span>
          <!-- Si no es un audio, mostrar el mensaje de contenido de archivo adjunto -->
          <span v-else class="message-content">
            {{ $t(attachmentMessageContent) }}
          </span>
        </span>
        <span v-else class="message-text message-content">
          {{ defaultEmptyMessage || $t('CHAT_LIST.NO_CONTENT') }}
        </span>
      </div>
    </div>

    <!-- Vista previa de la imagen o video a la derecha -->
    <div v-if="hasPreview" class="preview-container">
      <img
        v-if="isImage"
        :src="
          message.attachments[0].thumb_url || message.attachments[0].data_url
        "
        alt="Preview"
        class="preview-img"
      />
      <video
        v-else-if="isVideo"
        :src="message.attachments[0].data_url"
        playsinline
        class="preview-video"
      />
    </div>
  </div>
</template>

<script>
import { MESSAGE_TYPE } from 'widget/helpers/constants';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import { ATTACHMENT_ICONS } from 'shared/constants/messages';

export default {
  name: 'MessagePreview',
  mixins: [messageFormatterMixin],
  props: {
    message: {
      type: Object,
      required: true,
    },
    showMessageType: {
      type: Boolean,
      default: true,
    },
    defaultEmptyMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      audioDuration: null,
      audioObject: null,
    };
  },
  computed: {
    // Verifica si el mensaje tiene una vista previa de imagen o video
    hasPreview() {
      const attachments = this.message.attachments || [];
      return attachments.length > 0 && (this.isImage || this.isVideo);
    },

    // Verifica si el archivo adjunto es una imagen
    isImage() {
      return this.lastMessageFileType === 'image';
    },

    // Verifica si el archivo adjunto es un video
    isVideo() {
      return this.lastMessageFileType === 'video';
    },

    // Verifica si el archivo adjunto es un audio
    isAudio() {
      return this.lastMessageFileType === 'audio';
    },

    // Obtiene el tipo de archivo del último mensaje en el chat
    lastMessageFileType() {
      const [{ file_type: fileType } = {}] = this.message.attachments || [];
      return fileType;
    },

    /**
     * Obtiene el contenido parseado del último mensaje en el chat.
     * @returns {string} - El contenido parseado del último mensaje.
     */
    parsedLastMessage() {
      const { content_attributes: contentAttributes } = this.message;
      const { email: { subject } = {} } = contentAttributes || {};
      return this.getPlainText(subject || this.message.content);
    },

    /**
     * Obtiene el icono de adjunto para el último mensaje en el chat.
     * @returns {string} - El icono de adjunto correspondiente al tipo de archivo del último mensaje.
     */
    attachmentIcon() {
      return ATTACHMENT_ICONS[this.lastMessageFileType] || '';
    },

    /**
     * Obtiene el contenido del mensaje de adjunto.
     * @returns {string} - El contenido del mensaje de adjunto.
     */
    attachmentMessageContent() {
      return `CHAT_LIST.ATTACHMENTS.${this.lastMessageFileType}.CONTENT`;
    },
  },
  watch: {
    'message.attachments': 'loadAudioDuration',
  },
  mounted() {
    this.loadAudioDuration();
  },
  beforeDestroy() {
    if (this.audioObject) {
      this.audioObject.pause();
      this.audioObject = null;
    }
  },
  methods: {
    /**
     * Carga la duración del archivo de audio.
     */
    loadAudioDuration() {
      if (this.isAudio && this.message.attachments.length > 0) {
        const audioUrl = this.message.attachments[0].data_url;

        if (this.audioObject) {
          this.audioObject.pause();
        }

        this.audioObject = new Audio(audioUrl);

        this.audioObject.oncanplaythrough = () => {
          const duration = this.audioObject.duration;
          const minutes = Math.floor(duration / 60);
          const seconds = Math.floor(duration % 60);
          this.audioDuration = `${minutes}:${
            seconds < 10 ? '0' : ''
          }${seconds}`;
        };

        this.audioObject.onerror = () => {
          this.audioDuration = '00:00';
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.message-wrapper {
  position: relative;
}

.message-wrapper.has-preview {
  padding-right: 60px; /* Deja espacio a la derecha solo si hay un preview */
}

.contact-name {
  font-size: var(--font-size-mini);
  font-weight: var(--font-weight-bold);
  line-height: var(--space-normal);
  color: var(--conversa2-blue-700-color);
  cursor: pointer;
}

.message-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  position: relative;
  margin-top: 4px;
}

.message-text {
  font-size: var(--font-size-mini);
  font-weight: var(--font-weight-normal);
  line-height: var(--space-normal);

  display: inline-flex;
  align-items: flex-start;
  flex: 1;
  max-width: 100%;
}

.icon-attachment {
  margin-right: 0.4rem;
  flex-shrink: 0;
  align-self: flex-start;
}

.message-content {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Limitar a dos líneas */
  overflow: hidden; /* Ocultar el texto que se excede */
}

.preview-container {
  position: absolute;
  top: 50%; /* Centrado verticalmente con respecto al mensaje */
  right: 0; /* Alineado a la derecha de la burbuja */
  transform: translateY(-50%); /* Asegura que esté centrado verticalmente */
  width: 44px;
  height: 36px;
  overflow: hidden;
  border-radius: 2px;
  background-color: #f0f0f0;
  flex-shrink: 0;
}

.preview-img,
.preview-video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Asegura que la imagen o video cubran el contenedor */
}
</style>
