<template>
  <router-link
    v-slot="{ href, isActive, navigate }"
    :to="to"
    custom
    active-class="active"
  >
    <li :class="{ active: isActive }">
      <a
        :href="href"
        class="button clear menu-item text-truncate"
        :class="{ 'is-active': isActive, 'text-truncate': shouldTruncate }"
        @click="navigate"
      >
        <span v-if="icon" class="badge--icon">
          <fluent-icon
            v-if="icon !== 'brand-conversa'"
            class="inbox-icon"
            :icon="icon"
            size="15"
          />
          <svg
            v-else
            class="inbox-icon"
            width="16"
            height="16"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.44206 3.42186L0.306083 5.27597C-0.102028 5.62976 -0.102028 6.21257 0.306083 6.56636L0.604092 6.82474L1.36159 7.48241L3.26415 9.13394L3.21718 9.00769C2.87953 8.09605 3.1379 7.06256 3.86898 6.36231L5.18286 5.11008C5.51463 4.79446 5.51463 4.27184 5.18286 3.93713L4.78649 3.53636C4.16112 2.90511 3.11295 2.84052 2.44353 3.42186H2.44206Z"
              fill="currentColor"
            />
            <path
              d="M10.3925 8.03146L11.8135 6.41957C12.0616 6.13918 12.0616 5.68849 11.8135 5.4081L11.6286 5.19817L11.1412 4.64473L9.79647 3.11798L9.83171 3.23249C10.0827 4.04724 9.89189 4.92512 9.32817 5.52114L8.23156 6.67354C7.93649 6.98329 7.93649 7.47948 8.23156 7.77309L8.58095 8.11954C9.09623 8.63188 9.90804 8.58197 10.394 8.03146H10.3925Z"
              fill="currentColor"
            />
            <path
              d="M4.34014 10.0676L6.17958 11.6634C6.49814 11.9393 7.00607 11.8748 7.31289 11.5254L7.5375 11.2699L8.11003 10.6196L9.55897 8.97541L9.43712 9.02826C8.66934 9.35709 7.79147 9.21029 7.1749 8.62895L7.16168 8.61721L5.9535 7.46775C5.6276 7.15799 5.0903 7.1712 4.75119 7.50738L4.33867 7.91696C3.70742 8.54381 3.70742 9.52004 4.33867 10.0676H4.34014Z"
              fill="currentColor"
            />
            <path
              d="M8.9423 2.15054L7.31866 0.309637C7.01185 -0.0397526 6.50391 -0.104346 6.18535 0.171643L5.94753 0.378634L5.31921 0.92474L3.57227 2.44121L3.72934 2.40304C4.67328 2.17109 5.67448 2.47057 6.32922 3.15614L6.34243 3.17082L7.53153 4.42011C7.83247 4.73573 8.31105 4.74894 8.60172 4.45534L8.9423 4.11035C9.4517 3.59654 9.4517 2.72894 8.9423 2.15201V2.15054Z"
              fill="currentColor"
            />
          </svg>
        </span>
        <span
          v-if="labelColor"
          class="badge--label"
          :style="{ backgroundColor: labelColor }"
        />
        <span
          v-tooltip.bottom="{
            content: menuTitle,
          }"
          class="menu-label button__content"
          :class="{ 'text-truncate': shouldTruncate }"
        >
          {{ label }}
          <span v-if="showChildCount" class="count-view">
            {{ childItemCount }}
          </span>
        </span>
        <span v-if="count" class="badge" :class="{ secondary: !isActive }">
          {{ count }}
        </span>
        <span v-if="warningIcon" class="badge--icon">
          <fluent-icon
            v-tooltip.top-end="$t('SIDEBAR.FACEBOOK_REAUTHORIZE')"
            class="inbox-icon"
            :icon="warningIcon"
            size="12"
          />
        </span>
      </a>
    </li>
  </router-link>
</template>
<script>
export default {
  props: {
    to: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    labelColor: {
      type: String,
      default: '',
    },
    shouldTruncate: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    warningIcon: {
      type: String,
      default: '',
    },
    count: {
      type: String,
      default: '',
    },
    showChildCount: {
      type: Boolean,
      default: false,
    },
    childItemCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    showIcon() {
      return { 'text-truncate': this.shouldTruncate };
    },
    menuTitle() {
      return this.shouldTruncate ? this.label : '';
    },
  },
};
</script>
<style lang="scss" scoped>
$badge-size: var(--space-normal);
$label-badge-size: var(--space-slab);

.button {
  margin: var(--space-small) 0;
}

.menu-item {
  display: inline-flex;
  color: var(--conversa2-neutral-600-color);
  font-weight: var(--font-weight-normal);
  width: 100%;
  margin: 0;
  text-align: left;
  line-height: var(--space-two);
}

.nested.vertical.menu {
  li {
    border-radius: var(--border-radius-normal-plus);
    padding: var(--space-small);
    height: var(--space-large-plus);
    display: flex;
    flex-direction: column;
    transition: background-color 250ms ease-in-out;

    &:hover {
      background: var(--conversa2-neutral-50-color);
      a.button {
        background: var(--conversa2-neutral-50-color);
        color: var(--conversa2-neutral-800-color);
      }
    }

    a.button {
      &.clear {
        padding: 0;
      }
    }

    &:focus {
      border-color: var(--conversa2-blue-700-color);
    }

    &.active {
      background: var(--conversa2-blue-50-color);
      border-color: var(--conversa2-blue-50-color);
      a.button.clear {
        color: var(--conversa2-blue-700-color);
        background: var(--conversa2-blue-50-color);
      }
      .badge--icon {
        background: var(--conversa2-blue-100-color);
      }
    }
  }
}

.menu-label {
  flex-grow: 1;
}

.inbox-icon {
  font-size: var(--font-size-nano);
}

.badge--label,
.badge--icon {
  display: inline-flex;
  border-radius: var(--border-radius-micro);
  margin-right: var(--space-smaller);
  background: var(--conversa2-neutral-50-color);
}

.badge--icon {
  align-items: center;
  height: var(--space-two);
  justify-content: center;
  min-width: var(--space-two);
}

.badge--label {
  height: 16px;
  min-width: 16px;
  padding: var(--space-micro);
  border-radius: var(--border-radius-normal-small);
  border: 1px solid var(--conversa2-neutral-300-color);
}

.badge.secondary {
  min-width: unset;
  background: var(--s-75);
  color: var(--s-600);
  font-weight: var(--font-weight-bold);
}

.count-view {
  background: var(--s-50);
  border-radius: var(--border-radius-normal);
  color: var(--s-600);
  font-size: var(--font-size-micro);
  font-weight: var(--font-weight-bold);
  margin-left: var(--space-smaller);
  padding: var(--space-zero) var(--space-smaller);
  line-height: var(--font-size-small);
}
</style>
