export const LOCAL_STORAGE_KEYS = {
  DISMISSED_UPDATES: 'dismissedUpdates',
  WIDGET_BUILDER: 'widgetBubble_',
  DRAFT_MESSAGES: 'draftMessages',
  MESSAGE_REPLY_TO: 'messageReplyTo',
};

export const LocalStorage = {
  clearAll() {
    window.localStorage.clear();
  },

  get(key) {
    const value = window.localStorage.getItem(key);
    try {
      return typeof value === 'string' ? JSON.parse(value) : value;
    } catch (error) {
      return value;
    }
  },
  set(key, value) {
    if (typeof value === 'object') {
      window.localStorage.setItem(key, JSON.stringify(value));
    } else {
      window.localStorage.setItem(key, value);
    }
    window.localStorage.setItem(key + ':ts', Date.now());
  },

  remove(key) {
    window.localStorage.removeItem(key);
    window.localStorage.removeItem(key + ':ts');
  },

  /**
   * Actualiza un valor en el almacenamiento local en formato JSON. Si no existe, lo crea.
   *
   * @param {string} storeName - El nombre de la tienda (clave en `localStorage`).
   * @param {string} key - La clave dentro del objeto de almacenamiento.
   * @param {*} value - El valor que se va a almacenar.
   *
   * @returns {void} No devuelve ningún valor.
   */
  updateJsonStore(storeName, key, value) {
    try {
      const storedValue = this.get(storeName) || {};
      storedValue[key] = value;
      this.set(storeName, storedValue);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error updating JSON store in localStorage', e);
    }
  },

  /**
   * Obtiene un valor de un almacenamiento local en formato JSON.
   *
   * @param {string} storeName - El nombre de la tienda (clave en `localStorage`).
   * @param {string} key - La clave del valor que se desea obtener.
   *
   * @returns {*} Retorna el valor almacenado, o `null` si no se encuentra o ocurre un error.
   */
  getFromJsonStore(storeName, key) {
    try {
      const storedValue = this.get(storeName) || {};
      return storedValue[key] || null;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error getting value from JSON store in localStorage', e);
      return null;
    }
  },

  /**
   * Elimina una entrada del almacenamiento local en formato JSON.
   *
   * @param {string} storeName - El nombre de la tienda (clave en `localStorage`).
   * @param {string} key - La clave de la entrada que se desea eliminar.
   *
   * @returns {void} No devuelve ningún valor.
   */
  deleteFromJsonStore(storeName, key) {
    try {
      const storedValue = this.get(storeName);
      if (storedValue && key in storedValue) {
        delete storedValue[key];
        this.set(storeName, storedValue);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Error deleting entry from JSON store in localStorage', e);
    }
  },
};
